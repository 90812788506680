.PercentageBar {
  background-color: rgb(51, 51, 51);
  flex: 1;
  height: 5px;
  border-radius: 3px;
}

.Percentage {
  background-color: var(--textMainColor);
  height: 100%;
  border-radius: inherit;
}
