.Row {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.StarTypeNumber {
  margin: 0 5px;
}

.StarsNumber {
  width: 15%;
  margin-left: 5px;
}

.PercentageNumber {
  width: 10%;
  margin-left: 10px;
}

.Star {
  color: var(--textMainColor)
}