.DrivenSale {
  margin: 2rem auto;
}

.OrderFrom {
    margin-left: 10px;
}

.OrderName, .TotalPrice {
  color: var(--textMainColor);
}