.Input {
  width: calc(100% - 10px);
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 1rem;
}

.Search {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  color: var(--textMainColor);
}

.Search:hover {
  color: var(--textMainColorHover);
}
