.ThumnailWrapper {
  position: relative;
}

.ThumnailWrapper img {
  width: 100%;
  cursor: pointer;
}

.ThumnailWrapper img:hover {
  opacity: 0.9;
}

.PlayBtn {
  position: absolute;
  font-size: 48px;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  cursor: pointer;
}

.VideoWrapper {
  z-index: 500;
  position: fixed;
  width: 70%;
  top: 10%;
  left: 15%;
  text-align: center;
  max-height: 80%;
  overflow: scroll;
}

@media (min-width: 800px) {
  .VideoWrapper {
    max-width: 500px;
    left: calc(50% - 250px);
  }
}
