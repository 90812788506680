.ReviewCard {
  margin: 2rem auto;
}

.Review {
  padding: 10px;
}

.Header {
  margin-top: 0;
  font-size: 1.2rem;
}

.Header a {
  text-decoration: none;
  color: var(--textMainColor);
}

.Header a:hover {
  color: var(--textMainColorHover);
  border-bottom: var(--textMainColorHover);
}

.VisibleIcon {
  margin-left: 20px;
}

.CreatedAt {
  margin: 0 20px;
}

.Feedback {
  margin: 20px 0;
}

.Rating {
  margin: 10px 0;
}

.Image {
  max-width: 300px;
  float: right;
}

@media screen and (max-width: 900px) {
  .TextButtonArea,
  .Image {
    width: 100%;
    margin: 5px auto;
    float: none;
  }
}
