.TextAreaColumn {
  float: left;
  width: 80%;
}

.TextAreaColumn:hover {
  outline: none;
  border: 1px solid #1c87c9;
}

.ButtonsColumn {
  float: left;
  width: 20%;
  padding: 10px;
}

.ButtonsColumn button:disabled {
  visibility: hidden;
}

.ReplyAction {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  display: block;
}

.Row:after {
  content: "";
  display: table;
  clear: both;
}

.Row {
  margin: 10px 0;
}

.SendIcon {
  color: var(--textMainColor);
}

.SendIcon:hover {
  color: var(--textMainColorHover);
}

.CancelIcon {
  color: var(--textSecColor);
}

.CancelIcon:hover {
  color: var(--textSecColorHover);
}
