@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  background: #121212;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --backgroundMainColor: #424242;
  --backgroundSecColor: #121212;
  --textMainColor: #90caf9;
  --textMainColorHover: #3980bb;
  --textSecColor: #f48fb1;
  --textSecColorHover: #ff4382;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.column-10 {
  float: left;
  width: 10%;
}

.column-20 {
  float: left;
  width: 20%;
}

.column-30 {
  float: left;
  width: 30%;
}

.column-40 {
  float: left;
  width: 40%;
}

.column-50 {
  float: left;
  width: 50%;
}

.column-60 {
  float: left;
  width: 60%;
}

.column-70 {
  float: left;
  width: 70%;
}

.column-80 {
  float: left;
  width: 80%;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
  color: #fff;
}
