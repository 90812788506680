.Settings {
  max-width: 800px;
  margin: auto;
}

.SettingField {
  margin-bottom: 40px;
}

.UpdateAssets {
  text-align: center;
}
