.DataArea {
  height: 20rem;
  max-width: 25rem;
  margin: auto;
}

.Header {
  border-bottom: solid 1px rgba(255, 255, 255, 0.7);
  height: 15%;
  text-align: center;
}

.Number {
  margin-right: 10px;
  font-size: 30px;
  color: var(--textMainColor);
}

.Content {
  height: 70%;
}

.Footer {
  border-top: solid 1px rgba(255, 255, 255, 0.7);
  height: 10%;
  text-align: center;
  padding-top: 10px;
}