.anhnoiButton {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid var(--textSecColor);
  border-radius: 4px;
  background: var(--textSecColor);
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.anhnoiButton:focus {
  outline: none;
}

.anhnoiButton:hover,
.anhnoiButton:active {
  background: var(--textSecColorHover);
  border-color: var(--textSecColorHover);
}

.anhnoiButton--inverse {
  background: transparent;
  color: white;
  border-color: white;
}

.anhnoiButton--inverse:hover,
.anhnoiButton--inverse:active {
  color: white;
  background: #af1347;
}

.anhnoiButton--danger {
  background: #f34343;
  border-color: #f34343;
}

.anhnoiButton--danger:hover,
.anhnoiButton--danger:active {
  background: #830000;
  border-color: #830000;
}

.anhnoiButton:disabled,
.anhnoiButton:hover:disabled,
.anhnoiButton:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.anhnoiButton--small {
  font-size: 0.8rem;
  padding: 0.2rem 1rem;
}

.anhnoiButton--big {
  font-size: 1.5rem;
}

.anhnoiButton--default {
  font-size: 1rem;
}
