.DataColumn {
  float: left;
  width: 50%;
  padding: 15px;
  margin-bottom: 20px;
}

.LatestReviewsColumn {
  float: left;
  width: 100%;
  padding: 15px;
}

.Row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 1300px) {
  .DataColumn {
    width: 100%;
  }
  .Row {
    max-width: 40rem;
    margin: auto;
  }
}
