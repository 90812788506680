.ReplyTextRow {
  border-top: 1px solid grey;
  margin: 10px 0;
  padding: 10px;
}

.TextColumn {
  float: left;
  width: 80%;
}

.ButtonsColumn {
  float: right;
  width: 20%;
}

.ButtonsColumn button {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}

.ReplyTextRow:after {
  content: "";
  display: table;
  clear: both;
}

.EditIcon {
  color: var(--textMainColor);
}

.EditIcon:hover {
  color: var(--textMainColorHover);
}

.DeleteIcon {
  color: var(--textSecColor);
}

.DeleteIcon:hover {
  color: var(--textSecColorHover);
}
