.Header {
  font-size: 1.2rem;
  margin-left: 10px;
  color: var(--textMainColor);
  cursor: pointer;
  border-bottom: 1px dotted #fff;
  width: fit-content;
}

.StepsContainer {
  padding: 20px;
}

.StepWrapper:not(:first-child) {
  margin-top: 20px;
}

.StepTitle {
  font-weight: bold;
  font-size: 1.2rem;
}

.CloseModalBtn {
  margin-top: 10px;
  text-align: center;
}

.Image {
  width: 100%;
}

.Code {
  margin: 10px 0;
  padding: 10px;
  background-color: rgb(133, 133, 133);
  border-radius: 5px;
}

.SupportEmail {
  color: var(--textMainColor);
}
