.Conversion {
  margin-top: 20px;
}

.ClickConversion,
.SaleConversion {
  border: 2px solid grey;
  border-radius: 3px;
  padding: 10px;
  margin-right: 10px;
  text-align: center;
}

.DropdownIcon {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  cursor: pointer;
}

.DrivenSales {
  margin-top: 10px;
  text-align: left;
  padding-left: 20px;
}

.Number {
  color: var(--textMainColor);
}
