.TimeSelection {
  border: none;
  background-color: transparent;
  color: white;
  font-size: 1rem;
  padding: 10px;
  cursor: pointer;
}

.TimeSelection option {
  color: black;
}
