.Header {
    font-size: 1.7rem;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: center;
}

.LoadMore {
    text-align: center;
    margin: 20px;
}