.Image500 {
  width: 100%;
  cursor: pointer;
}

.Image500:hover {
  opacity: 0.9;
}

.Image720Area {
  z-index: 500;
  position: fixed;
  width: 70%;
  top: 10%;
  left: 15%;
  text-align: center;
  max-height: 80%;
  overflow: scroll;
}

@media (min-width: 800px) {
  .Image720Area {
    max-width: 500px;
    left: calc(50% - 250px);
  }
}

.Image720 {
  width: 100%;
}