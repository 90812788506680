.Tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.ShowBorderBottom {
  border-bottom: 1px dotted rgb(184, 184, 184);
}

.Tooltip .TooltipText {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 0.8rem;

  position: absolute;
  z-index: 1;
}

.Top {
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.Bottom {
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.Left {
  top: -5px;
  right: 105%;
}

.Right {
  top: -5px;
  left: 105%;
}

.Tooltip:hover .TooltipText {
  visibility: visible;
}
