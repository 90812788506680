.Error {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 100;
  background-color: #000000c4;
}

.ErrorIcon {
  color: var(--textSecColor);
}
